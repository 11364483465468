import React, { useEffect, useMemo, useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
// eslint-disable-next-line no-restricted-imports
import Container from "react-bootstrap/Container";
// eslint-disable-next-line no-restricted-imports
import Row from "react-bootstrap/Row";
// eslint-disable-next-line no-restricted-imports
import Col from "react-bootstrap/Col";
import config from "../../config/config";
import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState
} from "@devexpress/dx-react-grid";
import swal from "sweetalert";

export function MyPage8(props) {
  const [loading, setLoading] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  let cazz = [];

  useEffect(() => {
    GetCWD();
  }, []);

  function GetCWD() {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      },
      method: "GET"
    };

    fetch(config.apiUrl + "order/getcloseWorkDayGls.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
          setLoading(false);
        } else {
          setLoading(false);
          setDataOrder(result);
        }
      });
  }

  function CreateCloseWorkDay() {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      },
      method: "POST",
      body: JSON.stringify({
        data: dataSelected
      })
    };
    fetch(config.apiUrl + "order/CreateCloseWorkDayGls.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result !== false) {
          swal({
              title: "Ordine creato!",
              text: "OK",
              icon: "success",
              type: "Success"
            }
          ).then(function() {
            window.location.reload();
          });
        } else {
          swal({
              title: "Ordine non Creato!",
              text: "Controlla l'inserimento corretto",
              icon: "error",
              type: "Error"
            }
          ).then(function() {
            window.location.reload();
          });
        }


      })
      .catch(() => {
        setLoading(false);
      });
  }

  dataOrder.map((element) => {
    return cazz.push(element);
  });

  const [sorting, setSorting] = useState([{ columnName: "product_reference", direction: "asc" }]);
  const [selection, setSelection] = useState([]);
  let dataSelected = dataOrder.filter((el, key) => selection.includes(key));
  const [columns] = useState([

    { name: "nominativo", title: "Nominativo" },
    { name: "contatore_progressivo", title: "Cont Progressivo" },
    { name: "notifica", title: "Notifica" },
    { name: "notaAutista", title: "nota Autista" },
    { name: "indirizzo", title: "Indirizzo" },
    { name: "provincia", title: "Provincia" },
    { name: "city", title: "Città" },
    { name: "zipCode", title: "CAP" },
    { name: "colli", title: "colli" },
    { name: "peso", title: "peso" },
    { name: "volume", title: "volume" },
    { name: "colleType", title: "colleType" },
    { name: "contrassegno", title: "contrassegno" },
    { name: "serAcc", title: "serAcc" },
    { name: "note", title: "note" },
    { name: "notificaTel", title: "notificaTel" },
    { name: "bda", title: "bda" },
    { name: "asssicurazione", title: "assicurazione" },
    { name: "craeted_at", title: "created_at" }

  ]);

  const [defaultColumnWidths] = useState([
    { columnName: "nominativo", width: 120 },
    { columnName: "contatore_progressivo", width: 70 },
    { columnName: "notifica", width: 120 },
    { columnName: "notaAutista", width: 100 },
    { columnName: "indirizzo", width: 120 },
    { columnName: "provincia", width: 120 },
    { columnName: "city", width: 120 },
    { columnName: "zipCode", width: 100 },
    { columnName: "colli", width: 50 },
    { columnName: "peso", width: 50 },
    { columnName: "volume", width: 50 },
    { columnName: "colleType", width: 50 },
    { columnName: "contrassegno", width: 100 },
    { columnName: "serAcc", width: 100 },
    { columnName: "note", width: 100 },
    { columnName: "notificaTel", width: 100 },
    { columnName: "bda", width: 100 },
    { columnName: "asssicurazione", width: 100 },
    { columnName: "craeted_at", width: 200 }
  ]);


  return (
    <>
      <div className="container d-flex justify-content-end">
        <div className="row">
          <div className="col">
            <button
              type="submit"
              onClick={CreateCloseWorkDay}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              style={{ background: "#2f2d77", color: "#ffffff" }}
            >
              <span>Invia a GLS</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
         <span className="font-weight-bold">
           Total rows selected:
           {"  "}
           {selection.length}
        </span>
        </div>
      </div>
      <div className="card">
        <Grid
          rows={cazz}
          columns={columns}
        >
          <PagingState
            defaultCurrentPage={0}
            pageSize={15}
          />
          <IntegratedPaging />
          <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
          />
          <IntegratedSorting />
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <Table />
          <TableColumnResizing
            defaultColumnWidths={defaultColumnWidths}
          />
          <TableHeaderRow />
          <TableSelection
            selectByRowClick
          />
          <PagingPanel />
        </Grid>
      </div>
    </>
  );
}
