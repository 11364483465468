/* eslint-disable no-restricted-imports */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import config from "../../config/config";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";



export function MyPage6() {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  let cazz = [];

  useEffect(() => {
    GetData();
  }, []);

  function GetData() {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      },
      method: "GET"
    };

    fetch(config.apiUrl + "order/GetOrderWareHouse.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result === false) {
          setLoading(false);
        } else {
          setLoading(false);
          setDataOrder(result);
        }
      });
  }

  dataOrder.map((el) => {
    return cazz.push(el);
  });

  console.log(cazz);

  const [sorting, setSorting] = useState([{ columnName: "product_reference", direction: "asc" }]);

  const [columns] = useState([
    { name: "firstname", title: "Nome" },
    { name: "lastname", title: "Cognome" },
    { name: "id_order", title: "Id Ordine" },
    { name: "address", title: "Indirizzo" },
    { name: "corriere", title: "Ref. Corriere" },
    { name: "phone", title: "Telefono" },
    { name: "postcode", title: "Codice Postale" },
    { name: "product", title: "Prodotto" },
    { name: "product_reference", title: "Ref. Prodotto" },
    { name: "quantity", title: "Quantità" },
    { name: "reference", title: "Referenza" }
  ]);


  const [defaultColumnWidths] = useState([
    { columnName: "firstname", width: 120 },
    { columnName: "lastname", width: 120 },
    { columnName: "id_order", width: 100 },
    { columnName: "address", width: 180 },
    { columnName: "corriere", width: 150 },
    { columnName: "phone", width: 100 },
    { columnName: "postcode", width: 100 },
    { columnName: "product", width: 200 },
    { columnName: "product_reference", width: 150 },
    { columnName: "quantity", width: 100 },
    { columnName: "reference", width: 150 }
  ]);
  return (
    <>
      <div className="card">
        <Grid
          rows={cazz}
          columns={columns}
        >
          <PagingState
            defaultCurrentPage={0}
            pageSize={15}
          />
          <IntegratedPaging />
          <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
          />
          <IntegratedSorting />
          <Table />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
          <TableHeaderRow />
          <PagingPanel />
        </Grid>
      </div>
    </>
  );
}
