/* eslint-disable no-restricted-imports */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
//import Table from "react-bootstrap/Table";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import config from "../../config/config";
import { SelectionState } from "@devexpress/dx-react-grid";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
  TableSelection
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import swal from "sweetalert";


export function MyPage2() {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  let cazz = [];
  useEffect(() => {
    GetData();
  }, []);

  function GetData() {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      },
      method: "GET"
    };

    fetch(config.apiUrl + "order/GetAllOrder.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
          setLoading(true);
        } else {
          setLoading(false);
          setDataOrder(result);
        }
      });
  }

  dataOrder.map((element) => {
    cazz.push(element);
  });

  const [sorting, setSorting] = useState([{ columnName: "product_reference", direction: "asc" }]);
  const [selection, setSelection] = useState([]);
  let dataSelected = dataOrder.filter((el, key) => selection.includes(key));

function sendGls() {
  const requestOptions = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
    },
    method: "POST",
    body: JSON.stringify({
      data: dataSelected,
    })
  };
  fetch(config.apiUrl + "order/CreateParcellGlsMultiple.php", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      swal({
          title: "Ordine creato!",
          text: "OK",
          icon: "success",
          type: "Success"
        }
      ).then(function() {
        window.location.reload();
      });
    })
    .catch(() => {
      setLoading(false);
    });
}

  const [columns] = useState([
    { name: "firstname", title: "Nome" },
    { name: "lastname", title: "Cognome" },
    { name: "id_order", title: "Id Ordine" },
    { name: "email", title: "E-mail" },
    { name: "phone", title: "Telefono" },
    { name:"phone_mobile",title:"Cellulare"},
    { name: "postcode", title: "Codice postale" },
    { name: "provincia", title: "Provincia" },
    { name: "address", title: " Indirizzo" },
    { name: "city", title: "Citta" },
    { name: "reference", title: "Referenza" },
    {name: "payment", title:"Metodo di Pagamento"},
    {name:"total_paid", title:"€"},
  ]);

  const [defaultColumnWidths] = useState([
    { columnName: "firstname", width: 120 },
    { columnName: "lastname", width: 120 },
    { columnName: "id_order", width: 100 },
    { columnName: "email", width: 120 },
    { columnName: "phone", width: 120 },
    { columnName: "phone_mobile", width: 120 },
    { columnName: "postcode", width: 100 },
    { columnName: "provincia", width: 200 },
    { columnName: "address", width: 150 },
    { columnName: "postCode", width: 100 },
    { columnName: "city", width: 100 },
    { columnName: "reference", width: 150 },
    { columnName: "payment", width: 100 },
    { columnName: "total_paid", width: 100 },

  ]);


  return (
    <>
      <div className="container d-flex justify-content-end">
        <div className="row">
          <div className="col">
            <button
              type="submit"
              onClick={sendGls}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              style={{ background: "#2f2d77", color: "#ffffff" }}
            >
              <span>Invia a GLS</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
         <span className="font-weight-bold">
           Total rows selected:
           {"  "}
           {selection.length}
        </span>
        </div>
      </div>
      <div className="card">
        <Grid
          rows={cazz}
          columns={columns}
        >
          <PagingState
            defaultCurrentPage={0}
            pageSize={15}
          />
          <IntegratedPaging />
          <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
          />
          <IntegratedSorting />
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <Table />
          <TableColumnResizing
            defaultColumnWidths={defaultColumnWidths}
          />
          <TableHeaderRow />
          <TableSelection
            selectByRowClick
          />
          <PagingPanel />
        </Grid>
      </div>
    </>
  );
}
